.hero-article{

  @include media-breakpoint-down(md) {

  }

  &__intro{
    margin-top: 97px;

    @include media-breakpoint-down(md) {
      margin-top: 36px;
    }

    h1{
      max-width: 650px;
    }
    .lead-ingress{
      margin-top: 24px;
      margin-bottom: 60px;
      max-width: 540px;

      @include media-breakpoint-down(md) {
        margin-bottom: 36px;
      }
    }
  }

  &__image{
    position: relative;
    z-index: 0;

    figure{
      position: relative;
      aspect-ratio: 1024 / 500;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &:after{
      content: "";
      width: 100vw;
      height: 55%;
      background-color: $primary-white;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

  }


}

.login-page{

  &__wrapper{
    padding-top: 100px;
    padding-bottom: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 44px;
      padding-bottom: 44px;
    }
  }

  &__title{
    margin-bottom: 60px;
    h1{
      display: inline-block;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &__cards{
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .card{
      padding: 60px;
      width: calc(100% - 60px);

      @include media-breakpoint-down(md) {
        padding: 48px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 36px;
        margin: 0 0 24px 0!important;
        &:last-child{
          margin-bottom: 0!important;
        }
      }



      h3{
        color: $primary-black;
        margin-bottom: 24px;
        @include media-breakpoint-down(md) {
         margin-bottom: 16px;
        }
      }
      p{
        color: $primary-black;
        margin-bottom: 24px;
        @include media-breakpoint-down(md) {
         margin-bottom: 16px;
        }
      }
      &.min-side{
        margin-right: 30px;
      }
      &.allma{
        margin-left: 30px;
      }
    }

  }


}

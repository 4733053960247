$grid-columns: 12 !default;
$grid-gutter-width: 60px !default;

// Screen sizes
$screen-xs: 240px; // Small
$screen-sm: 768px; // Small
$screen-md: 992px; // Medium
$screen-lg: 1240px; // Large
$screen-xl: 1300px; // Extra large (1240px + 60px - 30px - 30px  = 1240px)

$grid-breakpoints: (
    xxs: 0,
    xs: $screen-xs,
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
    xl: $screen-xl
) !default;

$container-max-widths: (
    xs: 100%, // Extra small
    sm: 100%, // Small
    md: 100%, // Medium
    lg: 100%, // Large
    xl:  1300px // Extra large (maximum container size) (1240px + 60px - 30px - 30px  = 1240px)
) !default;

.container{
    @media (max-width: 1440px) { max-width: calc(100% - 140px) !important; }
    @media (max-width: 1240px) { max-width: initial !important; }
}

[class*="col-"]{
    @media (max-width: 1240px) { padding: 0 24px !important;}
    @media (max-width: 992px) { padding: 0 12px !important;}
}

.row{
    @media (max-width: 992px) { --bs-gutter-x: 42px !important;}
}


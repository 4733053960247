.article{

  &__content{
    .text-box{
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 78px;
      padding-bottom: 120px;
      @include media-breakpoint-down(md) {
        padding-top: 36px;
        padding-bottom: 44px;
      }
    }
    & + * {
      margin-top: 120px;
      @include media-breakpoint-down(md) {
        margin-top: 44px;
      }
    }
  }

}

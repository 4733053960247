.event-page {
   &__content {
      padding-block: 97px 126px;
      max-width: 650px;

      @include media-breakpoint-down(md) {
         padding-block: 44px;
         margin-inline: auto;
      }

      & > * {
         &:not(:last-child) {
            margin-bottom: 60px;

            @include media-breakpoint-down(md) {
               margin-bottom: 44px;
            }
         }
      }
   }

   &__intro {
      h1 {
         margin-bottom: 24px;
      }

      .lead-ingress.text-box {
         margin-bottom: 0;
      }
   }

   &__info {
      --icon-size: 24;

      @include media-breakpoint-down(md) {
         --icon-size: 20;
      }

      & > * {
         position: relative;
         display: block;
         line-height: var(--caption-size-height);
         padding-left: 48px;
         margin-bottom: 16px;
         font-size: 20px;

         @include media-breakpoint-down(md) {
            font-size: 16px;
            padding-left: 32px;
            margin-bottom: 12px;

            &::before {
               top: -2px;
            }
         }

         &:last-child {
            margin-bottom: 0;
         }

         &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
         }
      }

      .location {
         &::before {
            @include background-image('icons/map-pin.svg', 24, 24);
         }
      }

      .date {
         &::before {
            @include background-image('icons/date.svg', 24, 24);
         }
      }

      .time {
         &::before {
            @include background-image('icons/time.svg', 24, 24);
         }
      }
   }

   &__contact {
      .tags {
         margin-bottom: 16px;

         @include media-breakpoint-down(md) {
            margin-bottom: 36px;
         }

         .tag {
            margin-right: 16px;
            padding: 6px 12px;
            border-radius: 18px;
            border: 2px solid $primary-black;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            color: $primary-black;
            display: inline-block;
            margin-bottom: 8px;

            @include media-breakpoint-down(sm) {
               margin-right: 8px;
            }

            @media (hover: hover) {
               &:hover {
                  background: $primary-black;
                  color: $primary-white;
               }
            }

         }
      }

      .options {
         display: flex;

         @include media-breakpoint-down(sm) {
            display: block;
         }

         .share,
         .print,
         .mail {
            display: flex;
            align-items: center;
            font-size: 16px;

            @include media-breakpoint-down(sm) {
               margin-bottom: 16px;
            }

            a {
               display: flex;
               align-items: center;

               img {
                  margin-left: 8px;
               }
            }
         }

         .share {
            line-height: 100%;
            margin-right: 36px;
         }

         .print {
            margin-right: 36px;
         }
      }
   }
}

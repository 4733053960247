.hero-landing-page{
  margin-bottom: 100px;
  padding-bottom: 120px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 44px;
    padding-bottom: 36px;
  }

  &__text{
    margin-top: 133px;
    margin-bottom: 180px;
    max-width: 535px;
    background-color: inherit;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 100%;
    }

    h1,h2,h3,h4,h5,h6{
      margin-bottom: 24px;
    }
  }

  &__image{
    width: 100%;
    position: relative;

    figure{
      display: flex;
      // position: absolute;

      &.aspect-ratio-1x1{
        aspect-ratio: 1 / 1;
      }

      &.aspect-ratio-590x663{
        aspect-ratio: 590 / 663;
      }

      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &.desktop{
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &.mobile{
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }

      figure{
        position: relative;
        margin-bottom: 24px;
        aspect-ratio: 2 / 1;
      }
    }

  }

  &__list-links{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 60px 72px 0 72px;
    margin: 0 auto;
    max-width: 644px;
    z-index: 1;
    margin-top: -120px;

    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: 100%;
      margin-top: 0;
    }

    a{
      padding: 18px 68px 18px 16px;
      font-size: 20px;
      line-height: 160%;
      border-top: 2px solid $primary-black;
      position: relative;
      width: 100%;

      &:last-child{
        border-bottom: 2px solid $primary-black;
      }

      @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 16px 44px 16px 8px;
        border-bottom: 0;
      }

      &::after{
        content: "";
        @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -18px;
        @include media-breakpoint-down(md) {
          width: 24px;
          height: 24px;
          right: 8px;
          top: 50%;
          margin-top: -12px;
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          background-color: $primary-black;
          color: $primary-white;
          outline: none;

          &::after{
            @include background-image('icons/arrow-internal-link-light.svg', 36, 36);
            left: initial;
            right: 16px;
            @include media-breakpoint-down(md) {
              width: 24px;
              height: 24px;
              right:8px;
            }
          }
        }
      }

      &[target="_blank"]{
        &::after{
          @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
          @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
          }
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{

            &::after{
              @include background-image('icons/arrow-external-link-light.svg', 36, 36);

              @include media-breakpoint-down(md) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

      }

      &[href*="javascript"]{
        pointer-events: none;
        padding-left: 16px;

        @include media-breakpoint-down(md) {
          padding-left: 8px;
        }

        &:after{
          display: none;
        }
      }

    }

  }

}

*{
  // &:focus-visible{
  //   outline: 2px solid $primary-black;
  //   outline-offset: 2px;
  //   @include media-breakpoint-down(md) {
  //     outline: none;
  //   }
  // }
}

body{
  &.no-scroll{
    overflow: hidden;
    padding-right: 15px;
    height: 100%;
    @media (hover: none) {
      padding-right: 0;
    }
  }
}

.skip-to-main{
  position: absolute;
  left: -999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  &:focus-visible{
    display: block;
    width: calc(100% - 10px);
    z-index: 9999;
    height: auto;
    left: 5px;
    text-align: center;
    top: 7px;
    background-color: $primary-black;
    color: $primary-white;
    padding: 6px 0;
    border-radius: 4px;
    outline: 2px solid $primary-black;
    outline-offset: 2px;
  }
}

.page-content{
  overflow: hidden;
}

*{
  @media (hover: none) {
    // &:focus-visible{
    //   outline: auto!important;
    //   outline-offset: 5px;
    // }
  }
}

// Grid config
@import "./base/grid-config";

// Import bootstrap REBOOT and GRID
@import "./node_modules/bootstrap/scss/bootstrap-grid";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/mixins/_breakpoints";

// CSS Reset
@import "./base/reset";

//Base application
@import "./base/colors";
@import "./base/mixins";
@import "./base/typography";
@import "./base/image-aspect-ratio";
@import "./base/form";
@import "./base/global";

// Components
@import "./components/icons";
@import "./components/buttons";
@import "./components/dropdown";
@import "./components/form";
@import "./components/table";
@import "./components/breadcrumbs";
@import "./components/link";
@import "./components/form-group";

// Modules
@import "./modules/header";
@import "./modules/footer";
@import "./modules/hero-home";
@import "./modules/number-cards";
@import "./modules/text-media";
@import "./modules/link-card";
@import "./modules/news";
@import "./modules/search-card";
@import "./modules/hero-landing-page";
@import "./modules/text-box";
@import "./modules/link-list";
@import "./modules/hero-article";
@import "./modules/accordion";
@import "./modules/simple-card";
@import "./modules/video";
@import "./modules/contact-form";
@import "./modules/unit-deposit-calculator";

//Pages
@import "./pages/landing-page";
@import "./pages/article-list";
@import "./pages/article";
@import "./pages/login-page";
@import "./pages/search";
@import "./pages/find-forestry-manager";
@import "./pages/event-list";
@import "./pages/event-page";

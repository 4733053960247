// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  iframe{
    height: 100%;
    width: 100%;
  }

  // Padding trick to keep the image aspect ratio
  
  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }
  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
  }
  &.aspectRatio--16x9 {
    padding-top: calc((9 / 16) * 100%);
  }
}

.link{
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  line-height: normal;
  padding: 2px 4px;
  margin-bottom: 60px;
  border-color: transparent;
	outline: none;
  position: relative;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    margin-bottom: 36px;
  }

  span{
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    width: calc(100% - 36px);
    @include media-breakpoint-down(md) {
      width: calc(100% - 24px);
      text-decoration: none;
    }
  }

  i{
    margin-left: 8px;
    @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
    @include media-breakpoint-down(md) {  width: 24px; height: 24px; }
  }

  @media (hover: hover) {
    &:hover{
      i{
        @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
        @include media-breakpoint-down(md) {  width: 24px; height: 24px; }
      }
    }

    &:focus-visible{
      span{
        text-decoration: none;
      }
      i{
        @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        @include media-breakpoint-down(md) {  width: 24px; height: 24px; }
      }
      &:before{
        content: "";
        width: calc(100% + 4px);
        height: calc(100%);
        border: 2px solid  $primary-black;
        border-radius: 4px;
        position: absolute;
        left: -2px;
        top: 0;
      }
    }
  }

}

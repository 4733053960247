/* Checkbox */
.checkboxItem {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  min-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxItem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 2px solid $primary-black;
}

.checkboxItem:hover input ~ .checkmark {}

.checkboxItem input:checked ~ .checkmark {}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxItem input:checked ~ .checkmark:after {
  display: block;
}

.checkboxItem .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid $primary-black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Checkbox */

/* Custon Select */
.custom-select {
  position: relative;
  font-family: Arial;
  width: 100%;
  height: 54px;
}

.custom-select select {
  display: none;
}

.select-selected {
  position: relative;
  background-color: $primary-white;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid $primary-black;
  width: 100%;
  height: 100%;
  display: inline-block;
  outline: none;
  padding: 14px 50px 14px 21px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $primary-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 10px;
  right: 24px;
  @include background-image('icons/arrow-custom-select.svg', 16, 32);
  transition: all .15s linear;
}

.select-selected.select-arrow-active:after {
  transform: rotate(180deg);
}

.select-items div,.select-selected {
  cursor: pointer;
  user-select: none;
}

.select-items {
  position: absolute;
  background-color: $primary-white;
  border: 2px solid $primary-black;
  border-radius: 12px;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 99;
  overflow: hidden;
}

.select-hide {
  display: none;
}

.select-items div{
  padding: 11px 21px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $primary-black;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.select-items div:hover, .same-as-selected {
  background-color: $primary-black;
  color: $primary-white;
}

.same-as-selected{
  color: $primary-white!important;
}

/* Custon Select */
/* -- */

/* Captcha */
.g-recaptcha{
  margin-bottom: 24px;
}

.text-media{
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    margin-bottom: 48px;
  }

  &__media{
    display: flex;
    aspect-ratio: 1 / 1;
    align-items: center;
    height: 100%;
    margin-right: -58%;

    @include media-breakpoint-down(md) {
      margin-right: 0%;
		}

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__text{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 0 24px;
    }

    .content{
      padding: 80px 120px;
      background: $secondary-beige200;
      border: 2px solid $secondary-beige200;
      width: 100%;

      @include media-breakpoint-down(md) {
        padding: 36px;
        margin-top: -165px;
        min-height: 200px;
      }

      h2{
        margin-bottom: 24px;
        max-width: 567px;
        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
        }
      }

      p{
        margin-bottom: 24px;
        max-width: 450px;
        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
        }
      }

      .arrow{
        border: none;
        outline: none;
        background-color: transparent;
        @include background-image('icons/arrow-internal-link-outline-48.svg', 48, 48);
        @include media-breakpoint-down(md) {
          @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          border: 2px solid $primary-black;
          outline: none;
          .arrow{
            border: none;
            outline: none;
            @include background-image('icons/arrow-internal-link-dark-48.svg', 48, 48);
            @include media-breakpoint-down(md) {
              @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
            }
          }
        }
      }

      &[target="_blank"]{
        .arrow{
          @include background-image('icons/arrow-external-link-outline.svg', 48, 48);
          @include media-breakpoint-down(md) {
            @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
          }
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{
            .arrow{
              @include background-image('icons/arrow-external-link-dark.svg', 48, 48);
              @include media-breakpoint-down(md) {
                @include background-image('icons/arrow-external-link-dark.svg', 36, 36);
              }
            }
          }
        }


      }

    }

  }

}

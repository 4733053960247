.number-cards{
  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 44px;
  }

  &__cards{
    display: flex;
    justify-content: flex-start;
    gap: 60px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(md) {
      gap: 16px;
    }

    .card{
      width: calc(33.33% - 40px);
      padding: 48px;
      background-color: $secondary-beige200;

      @include media-breakpoint-down(lg) {
        // width: calc(50% - 30px);
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 30px 26px;
      }

      .number{
        font-size: 68px;

        @media (max-width: 1435px) {
          font-size: 50px;
        }

        @media (max-width: 1100px) {
          font-size: 40px;
        }

        @include media-breakpoint-down(md) {
          font-size: 36px;
        }
      }
      .info{
        font-size: 20px;
        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }
  }
}

.icon{
    display: inline-block;
    width: 24px;
    height: 24px;

    &--white{
        path{
            fill: white;
        }
    }
}
.article-list{
  &__wrapper{
    padding-top: 135px;
    padding-bottom: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 44px;
      padding-bottom: 44px;
    }
  }

  &__title{
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &__filter{
    margin-bottom: calc(100px - 15px);

    @include media-breakpoint-down(md) {
      margin-bottom: calc(44px - 15px);
    }

    h2{
      color: $primary-black;
      font-size: 18px!important;
      line-height: 150%!important;
      margin-bottom: 12px;
    }
    .options{
      display: flex;
      flex-wrap: wrap;
      a{
        margin: 0;
        margin-right: 16px;
        margin-bottom: 16px;

        background: transparent;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 12px;
        padding: 10px 32px; // 10px to offset 2px border width, top and bottom
        border-radius: 16px;
        font-size: 18px;
        line-height: normal;
        min-height: 54px;
        border: 2px solid transparent;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          padding: 7px 16px;
          border-radius: 12px;
          min-height: 42px;
        }

        border: 2px solid $primary-black;

        @media (hover: hover) {
          &:hover{
            color: $primary-white;
            background: $primary-black;
            border-color: transparent;
          }
        }

        &.active{
          color: $primary-white;
          background: $primary-black;
          border-color: transparent;
        }

      }
    }
  }

  &__list{
    .item{
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-bottom: 80px;

      @include media-breakpoint-down(sm) {
        flex-direction: column!important;
        margin-bottom: 36px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      &:nth-child(2n + 2){
        flex-direction: row-reverse;

        .image{
          margin-right: 0;
          margin-left: 30px;
          & + .info{
            margin-left: 0;
            margin-right: 30px;
          }
        }

      }

      .image{
        @include media-breakpoint-down(sm) {
          width: 100% !important;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }

      .info{
        @include media-breakpoint-down(sm) {
          width:100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }


      .image{
        margin-right: 30px;
        width: calc(100% - 60px);

        & + .info{
          width: calc(100% - 60px);
          margin-left: 30px;
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: 16px;
        }

        figure{
          display: flex;
          aspect-ratio: 482 / 364;
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

      }

      .info{

        time{
          margin-bottom: 16px;
          display: inline-block;
          @include media-breakpoint-down(sm) {
            margin-bottom: 8px;
          }
        }

        h4{
          margin-bottom: 24px;
          @include media-breakpoint-down(sm) {
            margin-bottom: 8px;
          }
        }

        p{
          margin-bottom: 24px;
          font-size: 18px;
          line-height: 150%;
          color: $primary-black;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-bottom: 8px;
          }
        }

      }

      .arrow{
        border: none;
        outline: none;
        background-color: transparent;
        @include background-image('icons/arrow-internal-link-outline-48.svg', 40, 40);
        @include media-breakpoint-down(md) {
          @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          // outline: none;
          .arrow{
            border: none;
            outline: none;
            @include background-image('icons/arrow-internal-link-dark-48.svg', 40, 40);
            @include media-breakpoint-down(md) {
              @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
            }
          }
        }
      }

      &[target="_blank"]{
        .arrow{
          @include background-image('icons/arrow-external-link-outline.svg', 40, 40);
          @include media-breakpoint-down(md) {
            @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
          }
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{
            .arrow{
              @include background-image('icons/arrow-external-link-dark.svg', 40, 40);
              @include media-breakpoint-down(md) {
                @include background-image('icons/arrow-external-link-dark.svg', 36, 36);
              }
            }
          }
        }

      }

    }
  }

  .spiner-wrapper {
    display: block;
    height: 50px;
    position: relative;
    margin-top: 120px;
    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }

  .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
    border-radius: 50%;
  }

  .spinner.smooth::after {
    border-top: 4px solid rgba(48, 27, 10, 1.0);
    border-left: 4px solid rgba(48, 27, 10, 1.0);
    border-right: 4px solid rgba(48, 27, 10, 1.0);
    border-bottom: 4px solid rgba(48, 27, 10, 0.0);
    animation: spinner .6s linear infinite;
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

}

.btn{
    background: transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 10px 32px; // 10px to offset 2px border width, top and bottom
    border-radius: 16px;
    font-size: 18px;
    line-height: normal;
    min-height: 54px;
    margin: 4px;
    outline: none;
    border: 2px solid transparent;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 7px 16px;
        border-radius: 12px;
        min-height: 42px;
        gap: 8px;
    }

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        & i{
            margin-left: 12px;
            @include media-breakpoint-down(md) {
                margin-left: 8px;
            }
        }
    }

    &.left-icon{
        flex-direction: row-reverse;
    }

    &--primary{
        color: $primary-white;
        background-color: $primary-black;

        @media (hover: hover) {
            &:hover{
                color: $primary-black;
                background: transparent;
                border-color: $primary-black;
                // @include media-breakpoint-down(md) {
                //     color: $primary-white;
                //     background-color: $primary-black;
                //     border-color: transparent;
                // }
            }

            &:focus-visible{
                @include outline-focus($primary-black, 18px);
                @include media-breakpoint-down(md) {
                    @include outline-focus($primary-black, 14px);
                }
                border-color: $primary-black;
                background-color: $primary-black;
                color: $primary-white;
            }
        }

        &:disabled{
            background-color: #AFAFBC;
            color: $primary-black;
            pointer-events: none;
        }
    }

    &--secondary{
        border: 2px solid $primary-black;

        @media (hover: hover) {
            &:hover{
                color: $primary-white;
                background: $primary-black;
                border-color: transparent;

                // @include media-breakpoint-down(md) {
                //     color: $primary-black;
                //     border: 2px solid $primary-black;
                //     background-color: transparent;
                // }

            }

            &:focus-visible{
                @include outline-focus($primary-black, 18px);
                @include media-breakpoint-down(md) {
                    @include outline-focus($primary-black, 14px);
                }
                color: $primary-black;
                border: 2px solid $primary-black!important;
                background-color: transparent;
            }
        }

    }

    // Icons
    &--primary{
        &.icon-search{
            i{ @include background-image('icons/search-outline-light.svg', 24, 24); }

            @media (hover: hover) {
                &:hover i{ @include background-image('icons/search-outline.svg', 24, 24);}

                // @include media-breakpoint-down(md) {
                //     &:hover i{ @include background-image('icons/search-outline-light.svg', 24, 24); }
                // }

                &:focus-visible i{ @include background-image('icons/search-outline-light.svg', 24, 24);}
            }
        }

        &.icon-external{
            span{
                // width: calc(100% - 36px);
                width: 100%;
            }
            i{
                @include background-image('icons/arrow-external-link-outline-light.svg', 36, 36);
                @include media-breakpoint-down(md){width: 24px!important; height: 24px!important;}
            }

            @media (hover: hover) {
                &:hover i{ @include background-image('icons/arrow-external-link-dark.svg', 36, 36);}

                // @include media-breakpoint-down(md) {
                //     &:hover i{ @include background-image('icons/arrow-external-link-outline-light.svg', 24, 24); }
                // }

                &:focus-visible i{ @include background-image('icons/arrow-external-link-outline-light.svg', 36, 36);}
            }
        }

    }

    &--secondary{

    }

}

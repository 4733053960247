.contact-form {
   padding-block: 97px 120px;

   @include media-breakpoint-down(md) {
      padding-block: 44px;
   }

   &__intro {
      .info {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 8px;
         margin-top: 48px;
         font-size: var(--lead-ingress-size);
         line-height: var(--lead-ingress-line-height);
         margin-bottom: 120px;

         @include media-breakpoint-down(md) {
            margin-top: 32px;
            margin-bottom: 44px;
         }

         a {
            text-decoration: underline;
            text-underline-offset: 10px;
            text-decoration-thickness: 2px;
            text-decoration-skip-ink: none;
         }
      }
   }

   &__form {
      .heading-2, .submit-message, .form-group {
         &:not(:last-child) {
            margin-bottom: 60px;

            @include media-breakpoint-down(md) {
               margin-bottom: 48px;
            }
         }
      }
   }
}

.unit-deposit-calculator{
  margin-bottom: 36px;

  &:last-child{
    margin-bottom: 0;
  }

  input{
    &[type="submit"]{
      margin: 0;

      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
}

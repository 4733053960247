.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @include media-breakpoint-up(sm) {
     max-width: 448px;
  }

  .input {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     gap: 4px;
     width: 100%;

     label {
        font-size: 18px;
        line-height: 22px;

        @include media-breakpoint-down(md) {
           font-size: 16px;
        }
     }

     input, textarea, .dropdown .dropdown-button, .dropdown-item {
        display: block;
        width: 100%;
        background-color: $primary-white;
        outline: 2px solid $primary-black;
        border: none;
        border-radius: 12px;
        padding: 12px 22px;
        font-size: 20px;
        line-height: 32px;
        font-family: inherit;

        @include media-breakpoint-down(md) {
           font-size: 16px;
           line-height: 19px;
           padding: 12px 16px;
        }

        &[type="number"]{
           -moz-appearance: textfield;

           &::-webkit-outer-spin-button,
           &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
           }
        }
     }

     .dropdown {
        width: 100%;

        .dropdown-button {
           display: flex;
           align-items: center;
           justify-content: space-between;
           cursor: pointer;

           &::after {
              @include background-image('icons/arrow-custom-select.svg', 16, 32);

              @include media-breakpoint-down(md) {
                 @include background-image('icons/arrow-custom-select.svg', 16, 19)
              }
           }

           &:focus-visible {
              outline: 2px solid $primary-black;
              outline-offset: 3px;
           }
        }

        .dropdown-menu {
           width: 100%;
           max-height: 265px;
           overflow: auto;
           background-color: $primary-white;
           outline: 2px solid $primary-black;
           border-radius: 12px;
           top: -2px;
           box-shadow: 4px 12px 10px #0006;

           @include media-breakpoint-down(md) {
              max-height: 205px;
           }

           .dropdown-item {
              outline: none;
              border-radius: 0px;
              cursor: pointer;

              &:hover, &:focus-visible {
                 background-color: #ece8de;
              }
           }
        }
     }

     @include media-breakpoint-up(sm) {
        &--size-1 {
           max-width: 180px;
        }

        &--size-2 {
           max-width: 280px;
        }
     }

  }
}

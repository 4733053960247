.link-card{
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    margin-bottom: 48px;
  }

  &__item{
    padding: 60px;
    background-color: $secondary-green200;
    border: 2px solid $secondary-green200;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    outline: none;
    margin-top: 24px;

    @include media-breakpoint-down(md) {
      padding: 36px;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }

    &:first-child{
      margin-top: 0;
    }

    .title{
      width: 37.5%;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 16px;
      }

    }

    .text{
      width: 62.4%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include media-breakpoint-down(md) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      .content{
        width: calc(100% - 48px);
        padding: 0 109px 0 60px;

        @include media-breakpoint-down(md) {
          width: 100%;
          padding: 0;
        }

      }

      .arrow{
        border: none;
        outline: none;
        background-color: transparent;
        @include background-image('icons/arrow-internal-link-outline-48.svg', 48, 48);
        @include media-breakpoint-down(md) {
          @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
          margin-top: 16px;
        }
      }

    }

    @media (hover: hover) {
      &:hover,
      &:focus-visible{
        border: 2px solid $primary-black;
        outline: none;
        .text .arrow{
          border: none;
          outline: none;
          @include background-image('icons/arrow-internal-link-dark-48.svg', 48, 48);
          @include media-breakpoint-down(md) {
            @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
          }
        }
      }
    }

    &[target="_blank"]{

      .arrow{
        @include background-image('icons/arrow-external-link-outline.svg', 48, 48);
        @include media-breakpoint-down(md) {
          @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
          margin-top: 16px;
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          .text .arrow{
            @include background-image('icons/arrow-external-link-dark.svg', 48, 48);
            @include media-breakpoint-down(md) {
              @include background-image('icons/arrow-external-link-dark.svg', 36, 36);
            }
          }
        }
      }

    }

  }

}

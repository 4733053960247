.breadcrumbs{
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  li{
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    margin-right: 6px;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:after{
      content: "";
      @include background-image('icons/arrow-bread-crumb.svg', 16, 16);
      margin-left: 6px;
    }

    &:last-child{
      &:after{
        display: none;
      }
      a{
        max-width: 200px;
      }
    }

    a{
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
      text-decoration-skip-ink: none;
      padding: 2px 4px 4px 4px;
      border-radius: 4px;
      outline: none;
      border: 2px solid transparent;
      max-width: 150px;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: visible;
      text-overflow: ellipsis;

      @media (hover: hover) {
        &:hover{
          background-color: $primary-black;
          color: $primary-white;
          text-decoration-line: none;
          padding: 2px 4px;
          margin-bottom: 2px;
        }

        &:focus-visible{
          background-color: transparent;
          text-decoration-line: none;
          border-color: $primary-black;
          color: $primary-black;
          padding: 2px 4px;
          margin-bottom: 2px;
        }
      }


      &[aria-current]{
        border: 2px solid transparent;
        padding: 2px 4px;
        border-radius: 4px;
        outline: none;
        text-decoration: none;

        @media (hover: hover) {
          &:focus-visible{
            border-color: $primary-black;
          }
        }

      }

    }

  }

  &--light{

    li{
      color: $primary-white;

      &:after{
        content: "";
        @include background-image('icons/arrow-bread-crumb-light.svg', 16, 16);
        margin-left: 8px;
      }

      a{

        @media (hover: hover) {
          &:hover{
            background-color: $primary-white;
            color: $primary-black;
            text-decoration: none;
          }

          &:focus-visible{
            background-color: transparent;
            text-decoration: none;
            border-color: $primary-white;
            color: $primary-white;
          }
        }

        &[aria-current]{
          border: 2px solid transparent;
          @media (hover: hover) {
            &:focus-visible{
              border-color: $primary-white;
            }
          }
        }

      }

    }

  }

}

// Primary colors
$primary-white: #FDF9EF; // Primary white
$primary-black: #301B0A; // Primary black

// Secondary colors
$secondary-green100: #EAF0D3; // Secondary green 100
$secondary-green200: #D0E0AF; // Secondary green 200
$secondary-green300: #B7CA90; // Secondary green 300
$secondary-green500: #99B06A; // Secondary green 500

$secondary-beige100: #FAEFD6; // Secondary beige 100
$secondary-beige200: #F6E7C3; // Secondary beige 200
$secondary-beige300: #DCCBA2; // Secondary beige 300
$secondary-beige500: #C5B181; // Secondary beige 500

// Background
.bg-primary-white { background-color: $primary-white; } // Primary white
.bg-primary-black {background-color: $primary-black; } // Primary black

.bg-secondary-green100 {background-color: $secondary-green100; } // Secondary green 100
.bg-secondary-green200 {background-color: $secondary-green200; } // Secondary green 200
.bg-secondary-green300 {background-color: $secondary-green300; } // Secondary green 300
.bg-secondary-green500 {background-color: $secondary-green500; } // Secondary green 500

.bg-secondary-beige100 { background-color: $secondary-beige100; } // Secondary beige 100
.bg-secondary-beige200 { background-color: $secondary-beige200; } // Secondary beige 200
.bg-secondary-beige300 { background-color: $secondary-beige300; } // Secondary beige 300
.bg-secondary-beige500 { background-color: $secondary-beige500; } // Secondary beige 500

.event-list, .events {
   &__header {
      padding-top: 135px;

      @include media-breakpoint-down(md) {
         padding-top: 44px;
      }
   }

   &__title {
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
         margin-bottom: 24px;
      }
   }

   &__filter {
      margin-bottom: 120px;

      @include media-breakpoint-down(md) {
         margin-bottom: 44px;
      }

      h2 {
         color: $primary-black;
         font-size: 18px !important;
         line-height: 150% !important;
         margin-bottom: 12px;
      }

      .options {
         display: flex;
         flex-wrap: wrap;

         a {
            margin: 0;
            margin-right: 16px;
            margin-bottom: 16px;

            background: transparent;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            gap: 12px;
            padding: 10px 32px; // 10px to offset 2px border width, top and bottom
            border-radius: 16px;
            font-size: 18px;
            line-height: normal;
            min-height: 54px;
            border: 2px solid transparent;

            @include media-breakpoint-down(md) {
               font-size: 16px;
               padding: 7px 16px;
               border-radius: 12px;
               min-height: 42px;
            }

            border: 2px solid $primary-black;

            @media (hover: hover) {
               &:hover {
                  color: $primary-white;
                  background: $primary-black;
                  border-color: transparent;
               }
            }

            &.active {
               color: $primary-white;
               background: $primary-black;
               border-color: transparent;
            }

         }
      }
   }

   &__list {
      margin-bottom: 120px;

      @include media-breakpoint-down(md) {
         margin-bottom: 44px;
      }

      .list-header {
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 20px;
         margin-bottom: 36px;

         @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
         }

         .displayMode {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 12px;

            &::after {
               @include background-image('icons/arrow-internal-link-outline.svg', 32, 32);
            }

            &:hover {
               &::after {
                  @include background-image('icons/arrow-internal-link-dark.svg', 32, 32);
               }
            }

            &--upcoming {
               .upcoming {
                  display: block;
               }

               .previous {
                  display: none;
               }
            }

            &--previous {
               .upcoming {
                  display: none;
               }

               .previous {
                  display: block;
               }

               &::after {
                  rotate: 180deg;
               }
            }
         }
      }



      .item {
         --gap: 60px;
         display: flex;
         gap: var(--gap);
         width: 100%;
         padding: 60px;
         position: relative;

         .info {
            width: calc(41.6667% - var(--gap) * 0.6667);

            .date {
               display: inline-block;
               border-radius: 8px;
               background-color: $primary-black;
               color: $primary-white;
               padding: 8px 16px;
               margin-bottom: 24px;
            }

            // .description {}
         }

         .detail {
            --icon-size: 24;
            width: calc(41.6667% - var(--gap) * 0.6667);

            @include media-breakpoint-down(md) {
               --icon-size: 20;
            }

            & > * {
               display: block;
               margin-bottom: 24px;

               &:not(.body-paragraph) {
                  position: relative;
                  font-size: var(--caption-size);
                  line-height: var(--caption-size-height);
                  padding-left: 48px;
                  margin-bottom: 16px;

                  @include media-breakpoint-down(md) {
                     padding-left: 32px;
                     margin-bottom: 12px;
                  }

                  &:last-child {
                     margin-bottom: 0;
                  }

                  &::before {
                     content: "";
                     position: absolute;
                     top: 0;
                     left: 0;
                  }
               }
            }

            .location, .date, .time {
               @include media-breakpoint-down(md) {
                  font-size: 14px !important;
                  &::before {
                     top: -2px;
                  }
               }
            }

            .location {
               &::before {
                  @include background-image('icons/map-pin.svg', 24, 24);
               }
            }

            .date {
               &::before {
                  @include background-image('icons/date.svg', 24, 24);
               }
            }

            .time {
               &::before {
                  @include background-image('icons/time.svg', 24, 24);
               }
            }
         }

         .arrow {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: calc(16.6667% - var(--gap) * 0.6667);

            &::before {
               @include background-image('icons/arrow-internal-link-outline.svg', 40, 40);

               @include media-breakpoint-down(md) {
                  @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
               }
            }

            @include media-breakpoint-down(md) {
               justify-content: flex-start;
            }
         }

         .card-link {
            display: block;
            position: absolute;
            z-index: 9;
            inset: 0;

            &[target="_blank"] {
               & + .arrow::before {
                  @include background-image('icons/arrow-external-link-outline.svg', 40, 40);

                  @include media-breakpoint-down(md) {
                     @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
                  }
               }
            }
         }

         & + .item {
            margin-top: 30px;

            @include media-breakpoint-down(md) {
               margin-top: 16px;
            }
         }

         @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 24px;
            padding: 36px;

            .info, .detail, .arrow {
               width: 100%;
            }
         }

         &:hover {
            outline: 2px solid $primary-black;

            .arrow {
               &::before {
                  @include background-image('icons/arrow-internal-link-dark.svg', 40, 40);

                  @include media-breakpoint-down(md) {
                     @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
                  }
               }
            }

            .card-link {
               &[target="_blank"] + .arrow {
                  &::before {
                     @include background-image('icons/arrow-external-link-dark.svg', 40, 40);

                     @include media-breakpoint-down(md) {
                        @include background-image('icons/arrow-external-link-dark.svg', 36, 36);
                     }
                  }
               }
            }
         }
      }

      .spiner-wrapper {
         display: block;
         height: 50px;
         position: relative;
         margin-top: 120px;

         @include media-breakpoint-down(md) {
            margin-top: 60px;
         }
      }

      .spinner::after {
         content: '';
         box-sizing: border-box;
         width: 50px;
         height: 50px;
         position: absolute;
         bottom: 0;
         left: calc(50% - 25px);
         border-radius: 50%;
      }

      .spinner.smooth::after {
         border-top: 4px solid rgba(48, 27, 10, 1.0);
         border-left: 4px solid rgba(48, 27, 10, 1.0);
         border-right: 4px solid rgba(48, 27, 10, 1.0);
         border-bottom: 4px solid rgba(48, 27, 10, 0.0);
         animation: spinner .6s linear infinite;
      }

      @keyframes spinner {
         to {
            transform: rotate(360deg);
         }
      }

      .btn.btn--secondary {
         margin-top: 36px;

         @include media-breakpoint-down(md) {
            margin-top: 16px;
         }
      }
   }
}

.events {
   margin-bottom: 100px;

   @include media-breakpoint-down(md) {
      margin-bottom: 48px;
   }

   .event-list__list {
      margin-bottom: 0px;
   }
}

.accordion{
  padding: 120px 0;
  // margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    padding: 44px 0;
    // margin-bottom: 44px;
  }

  & + .accordion{
    padding-top: 0;
  }

  &__items{

  }

  &__title{
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &__item{
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &-header{
      cursor: pointer;
      width: 100%;
      background-color: $secondary-beige200;
      padding: 28px 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      border: none;
      outline: none;

      @include media-breakpoint-down(md) {
        padding: 16px;
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          background-color: $primary-black;

          .arrow {
            @include background-image('icons/arrow-up-accordion-light.svg', 24, 24);
            & + span{
              color: $primary-white;
            }
          }
        }
      }

      .arrow{
        @include background-image('icons/arrow-up-accordion-black.svg', 24, 24);
        margin-top: 4px;

        @include media-breakpoint-down(md) {
          width: 20px!important;
          height: 20px!important;
        }

        & + span{
          width: calc(100% - 24px);
        }

      }

      .arrow + span{
        color: $primary-black;
        font-size: 20px;
        font-weight: 500;
        line-height: 150%;
        padding-left: 24px;
        @include media-breakpoint-down(md) {
          font-size: 18px;
          padding-left: 18px;
        }
      }

      &[aria-expanded="false"]{

        .arrow{
          @include background-image('icons/arrow-down-accordion-dark-outline.svg', 24, 24);
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{
            .arrow{
              @include background-image('icons/arrow-down-accordion-light.svg', 24, 24);
            }
          }
        }

        & + .collapse{
          display: none;
        }
      }

    }

    &-body{
      width: 100%;
      background-color: $secondary-beige200;
      padding: 24px 24px 30px 72px;
      margin-top: -2px;
      @include media-breakpoint-down(md) {
        padding: 16px;
      }
      p{
        color: $primary-black;;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }
  }

  &__button{
    margin-top: 60px;
    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
  }
}

.search-page{

  &__wrapper{
    padding-top: 100px;
    padding-bottom: 104px;

    @include media-breakpoint-down(md) {
      padding-top: 44px;
      padding-bottom: 24px;
    }
  }

  &__title{
    margin-bottom: 60px;
    h1{
      display: inline-block;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &__input{
    .input-group{
      position: relative;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
      }

      ::placeholder {
        color: $primary-black;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $primary-black;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
      color: $primary-black;
      }

      input[type="text"]{
        width: 100%;
        background-color: $primary-white;
        padding: 8px 165px 6px 24px;
        border: 2px solid $primary-black;
        color: $primary-black;
        font-size: 20px;
        line-height: 1.3;
        height: 78px;
        outline: none;
        @include media-breakpoint-down(md) {
          margin-bottom: 12px;
          border-radius: 12px;
          padding: 16px 16px 16px 24px;
          font-size: 16px;
          height: 53px;
        }
      }

      button{
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -27px;
        @include media-breakpoint-down(md) {
          position: relative;
          top: initial;
          right: initial;
          margin-top: 4px;
          margin-left: 0;
          &:after{
            border-radius: 15px;
          }
        }
      }

      .filter-toggle{
        display: none;
        padding: 0;
        border: none;
        background: transparent;
        width: 26px;
        margin-left: 20px;
        text-decoration: none;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          display: inline-flex;
        }

        img{
          width: 100%;
        }
      }

      .showing{
        display: none;

        @include media-breakpoint-down(md) {
          display: inline-flex;
          align-items: center;
          margin-left: 20px;

        }
      }

    }
  }

  &__no-results {
    font-size: 20px;
    line-height: 1.3;
    margin-top: 64px;

    @include media-breakpoint-down(md) {
      margin-block: 48px;
      text-align: center;
    }
  }

  &__filter{
    margin-bottom: 120px;


    @include media-breakpoint-down(md) {
      display: none;

      &.active{
        display: block;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .group{
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: 24px;
      }
      .title{
        margin-bottom: 16px;
        color: $primary-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
      ul{
        li{
          margin-bottom: 20px;
          color: $primary-black;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;

          @include media-breakpoint-down(md) {
            font-size: 16px;
          }

          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }

    &__result{
      margin-bottom: 120px;

      @include media-breakpoint-down(md) {
        margin-bottom: 44px;
      }

      .header{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 16px;

        @include media-breakpoint-down(md) {
          display: none;
        }

        p{
          color: $primary-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
        }

      }
      .list{
        .item{
          display: flex;
          padding: 48px 0;
          border-bottom: 2px solid $primary-black;
          text-decoration: none;

          &:first-child{
            border-top: 2px solid $primary-black;
          }

          @include media-breakpoint-down(md) {
            padding: 24px 0;
          }

          @media (hover: hover) {
            &:hover,
            &:focus-visible{
              .content{
                &:after{
                  @include background-image('icons/arrow-internal-link-dark-48.svg', 40, 40);

                  @include media-breakpoint-down(md) {
                    @include background-image('icons/arrow-internal-link-dark.svg', 36, 36);
                  }
                }
              }
            }
          }

          .content{
            padding-right: 158px;
            position: relative;

            @include media-breakpoint-down(md) {
              padding-right: 60px;
            }

            &:after{
              content: "";
              position: absolute;
              right: 36px;
              top: 50%;
              transform: translateY(-50%);

              @include media-breakpoint-down(md) {
                top: 20px;
                right: 0;
              }

              @include background-image('icons/arrow-internal-link-outline-48.svg', 40, 40);

              @include media-breakpoint-down(md) {
                @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
              }

            }

            h4{
              margin-bottom: 24px;
              @include media-breakpoint-down(md) {
                margin-bottom: 16px;
              }
            }
            p{
              color: $primary-black;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 160%;

              @include media-breakpoint-down(md) {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 16px;
              }

            }
          }
        }
      }
    }


}

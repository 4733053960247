.link-list{
  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 44px;
  }

  &__wrapper{
    &[class*="bg-"]{
      padding: 120px 0;

      @include media-breakpoint-down(sm) {
        padding: 36px 0;
      }
    }

    .row .offset-md-2.col-md-8{
      @include media-breakpoint-down(lg) {
        padding: 0 28px !important;
      }
    }
  }

  &__title{
    margin-bottom: 48px;
    max-width: 500px;
    @include media-breakpoint-down(md) {
      padding: 0 36px;
      margin-bottom: 36px;
    }
  }

  &__list{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    z-index: 1;

    @include media-breakpoint-down(md) {
      padding: 0 36px;
      max-width: 100%;
    }

    a{
      padding: 20px 68px 20px 16px;
      font-size: 20px;
      line-height: 160%;
      border-top: 2px solid $primary-black;
      position: relative;
      width: 100%;

      &:last-child{
        border-bottom: 2px solid $primary-black;
      }

      @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 16px 44px 16px 8px;
        border-bottom: 0;
      }

      &::after{
        content: "";
        @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -18px;
        @include media-breakpoint-down(md) {
          width: 24px;
          height: 24px;
          right: 8px;
          margin-top: -12px;
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          background-color: $primary-black;
          color: $primary-white;
          outline: none;

          &::after{
            @include background-image('icons/arrow-internal-link-light.svg', 36, 36);

            @include media-breakpoint-down(md) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &[target="_blank"]{
        &::after{
          @include background-image('icons/arrow-external-link-outline.svg', 36, 36);

          @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
          }
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{

            &::after{
              @include background-image('icons/arrow-external-link-light.svg', 36, 36);
              left: initial;
              @include media-breakpoint-down(md) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

      }

      &[href*="javascript"]{
        pointer-events: none;

        &:after{
          display: none;
        }
      }

    }
  }
}

.simple-card{
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    margin-bottom: 44px;
  }

  .item{
    padding: 36px 48px;
    max-width: 450px;

    @include media-breakpoint-down(md) {
      padding: 28px;
    }


    h3{
      margin-bottom: 16px;
    }
    p{
      color: $primary-black;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }

      a{
        text-decoration-line: underline;
        text-underline-offset: 2px;
        text-decoration-skip-ink: none;
      }

    }
  }

}

.search-card{

  // &.bg-secondary-green200 .search-card__wrapper{ background-color:$secondary-green200; }
  // &.bg-secondary-green300 .search-card__wrapper{ background-color: $secondary-green300; }
  // &.bg-secondary-beige300 .search-card__wrapper{ background-color: $secondary-beige300; }

  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 44px;
  }

  &__wrapper{
    padding: 100px 0;
    @include media-breakpoint-down(md) {
      padding: 30px 36px;
    }
    .content{
      width: 65%;
      margin: 0 auto;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  h2{
    margin-bottom: 24px;
    max-width: 580px;
    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  p{
    margin-bottom: 48px;
    max-width: 466px;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  .input-group{
    position: relative;

    ::placeholder {
      color: $primary-black;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: $primary-black;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
     color: $primary-black;
    }

    input[type="text"]{
      width: 100%;
      background-color: $primary-white;
      padding: 8px 165px 6px 24px;
      border: 2px solid $primary-black;
      color: $primary-black;
      font-size: 20px;
      line-height: 1.3;
      height: 78px;
      outline: none;
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
        border-radius: 12px;
        padding: 16px 16px 16px 24px;
        font-size: 16px;
        height: 53px;
      }
    }

    button{
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -27px;
      @include media-breakpoint-down(md) {
        position: relative;
        top: initial;
        right: initial;
        margin-top: 4px;
      }
    }

  }

}

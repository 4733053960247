$html-font-size: 16px;
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin font($family, $weight, $style) {
	font-family: $family, sans-serif;
	font-weight: $weight;
  font-style: $style;
  font-display:swap;
}

@mixin background-image($image, $width, $height) {
  background-image: url("../images/"+$image);
  background-repeat: no-repeat;
  background-position: center center;
  width: $width + px;
  height: $height + px;
  content: "";
  display: inline-block;
  background-size: contain;
}

@mixin outline-focus($color, $radius) {
  border-color: transparent;
	outline: none;
  position: relative;
  &::after{
    content: "";
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border: 2px solid $color;
    border-radius: $radius;
    position: absolute;
    top: -6px;
    left: -6px;
    // transform: translate(-50%, -50%);
  }
}

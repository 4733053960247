.text-box{
  // margin-top: 20px;
  margin-bottom: 120px;

  @include media-breakpoint-down(sm) {
    // margin-top: 0;
    margin-bottom: 44px;
  }

  &__wrapper{

    &[class*="bg-"]{
      padding: 120px 0;

      @include media-breakpoint-down(sm) {
        padding: 36px 0;
      }
    }

    .row .offset-md-2.col-md-8{
      @include media-breakpoint-down(lg) {
        // padding: 0 28px !important;
      }
    }

  }

  &__header{
    max-width: 580px;

    @include media-breakpoint-down(md) {
      padding: 0 36px;
      max-width: 100%;
    }

    & + .text-box__content{
      margin-top: 60px;
      @include media-breakpoint-down(md) {
        margin-top: 36px;
      }
    }
  }

  &__info-article{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
      margin-bottom: 44px;
      padding: 0 36px;
    }

    .author,
    .datetime,
    .share{
      font-size: 16px;
      font-weight: 500;
      line-height: 160%;
      color: $primary-black;
      display: inline-flex;
      align-items: center;
    }

    .author{
      margin-bottom: 4px;
    }

    .datetime{
      margin-bottom: 16px;
    }

    .share{
      img{
        margin-right: 12px;
      }
    }
  }

  &__content{
    max-width: 540px;

    @include media-breakpoint-down(md) {
      padding: 0 36px;
      max-width: 100%;
    }

    a:not(.btn) {
      text-decoration: underline;
    }

    figure {
      &.editor-align-right,
      &.editor-align-left {
        margin-top: 0;
        margin-bottom: 32px;

        @include media-breakpoint-down(sm) {
          width: 100% !important;
        }

        &:last-child {
          margin-bottom: 0;
        }

        figcaption {
          &:empty {
            display: none;
          }

          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }
      }

      &.editor-align-right {
        margin-left: 32px;

        @include media-breakpoint-down(sm) {
          float: none !important;
          margin-right: 0;
          margin-left: 0;
        }
      }

      &.editor-align-left {
        margin-right: 32px;

        @include media-breakpoint-down(sm) {
          float: none !important;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

  // Macros
  .simple-card{
    margin-top: 60px;
    margin-bottom: 0!important;

    & + *{
      margin-top: 60px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 44px;
      & + *{
        margin-top: 44px;
      }
    }
  }
  }

  &__footer{
    margin-top: 60px;

    @include media-breakpoint-down(md) {
      padding: 0 36px;
      margin-top: 44px;
    }

    .tags{
      margin-bottom: 16px;

      @include media-breakpoint-down(md) {
        margin-bottom: 36px;
      }

      .tag{
        margin-right: 16px;
        padding: 6px 12px;
        border-radius: 18px;
        border: 2px solid $primary-black;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: $primary-black;
        display: inline-block;
        margin-bottom: 8px;

        @include media-breakpoint-down(sm) {
          margin-right: 8px;
        }

        @media (hover: hover) {
          &:hover{
            background: $primary-black;
            color: $primary-white;
          }
        }

      }
    }

    .options{
      display: flex;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      .share,
      .print,
      .mail{
        display: flex;
        align-items: center;
        font-size: 16px;

        @include media-breakpoint-down(sm) {
         margin-bottom: 16px;
        }

        a{
          display: flex;
          align-items: center;
          img{
            margin-left: 8px;
          }
        }
      }

      .share{
        line-height: 100%;
        margin-right: 36px;
      }

      .print{
        margin-right: 36px;
      }


    }

  }

  h1,h2,h3,h4,h5,h6{ margin-bottom: 16px; }

  h1 + .lead-ingress,
  h2 + .lead-ingress,
  h3 + .lead-ingress,
  h4 + .lead-ingress,
  h5 + .lead-ingress,
  h6 + .lead-ingress{
    margin-top: 24px;
  }

  p{
    font-size: var(--body-size);
    line-height: var(--body-line-height);
    margin-bottom: 36px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6{
      margin-top: 60px;
      @include media-breakpoint-down(md) {
        margin-top: 36px;
      }
    }

    a{
      text-decoration: underline;
    }
  }

  .lead-ingress p{
    font-size: inherit;
    line-height: inherit;
  }

  .btn{
    margin-top: 24px;

    &:last-of-type{
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: 44px;
      }
    }

  }

  figure{

    &.editor-align-justify{
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: 44px;
      }
    }

    figcaption{
      margin-top: 8px;

      font-size: var(--caption-size)!important;
      line-height: var(--caption-size-height)!important;
    }

  }

  ul,ol{
    font-size: var(--body-size);
    line-height: var(--body-line-height);
    margin-bottom: 60px;
    padding-left: 46px;
    li{
      margin-bottom: 16px;
    }
  }

  ul{
    li{
      position: relative;
      &:after{
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 12px;
        position: absolute;
        right: calc(100% + 25px);
        top: 9px;
        background-color: $primary-black;
      }
    }
  }

  ol{
    list-style: decimal;
  }
}

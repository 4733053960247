.landing-page{

  &.bg-secondary-beige100{
    .main-header,
    .hero-landing-page,
    .hero-landing-page__list-links{
      background-color: $secondary-beige300;
    }
  }

  &.bg-secondary-green100{
    .main-header,
    .hero-landing-page,
    .hero-landing-page__list-links{
      background-color: $secondary-green200;
    }
  }

  &.bg-secondary-green100-2{
    background-color: $secondary-green100;
    .main-header,
    .hero-landing-page,
    .hero-landing-page__list-links{
      background-color: $secondary-green300;
    }
  }

}

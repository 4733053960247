@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

// ---------------------------------------------------------------------- //
// Font: Albert Sans                                                      //
// ---------------------------------------------------------------------- //
// Thin         (100)                                                     //
// ExtraLight   (200)                                                     //
// Light        (300)                                                     //
// Reguar       (400)                                                     //
// Medium       (500)                                                     //
// Semibold     (600)                                                     //
// Bold         (700)                                                     //
// ExtraBold    (800)                                                     //
// Black        (900)                                                     //
// ---------------------------------------------------------------------- //

// Font weights
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

:root {
  --heading-1-size: 48px; --heading-1-line-height: 1.3;
	--heading-2-size: 38px; --heading-2-line-height: 1.3;
	--heading-3-size: 28px; --heading-3-line-height: 1.3;
	--heading-4-size: 24px; --heading-4-line-height: 1.3;
	--sub-heading-size: 20px; --sub-heading-line-height: 1.3;
	--lead-ingress-size: 24px; --lead-ingress-line-height: 1.6;
	--body-size: 20px; --body-line-height: 1.6;
	--caption-size: 16px; --caption-size-height: 1.6;

	@media (max-width: 1239px) {
		--heading-1-size: 36px; --heading-1-line-height: 1.3;
		--heading-2-size: 30px; --heading-2-line-height: 1.3;
		--heading-3-size: 26px; --heading-3-line-height: 1.3;
		--heading-4-size: 22px; --heading-4-line-height: 1.3;
		--sub-heading-size: 18px; --sub-heading-line-height: 1.3;
		--lead-ingress-size: 22px; --lead-ingress-line-height: 1.6;
		--body-size: 18px; --body-line-height: 1.6;
		--caption-size: 14px; --caption-size-height: 1.5;
	}

	@media (max-width: 599px) {
		--heading-1-size: 28px; --heading-1-line-height: 1.3;
		--heading-2-size: 24px; --heading-2-line-height: 1.3;
		--heading-3-size: 22px; --heading-3-line-height: 1.3;
		--heading-4-size: 20px; --heading-4-line-height: 1.3;
		--sub-heading-size: 16px; --sub-heading-line-height: 1.3;
		--lead-ingress-size: 20px; --lead-ingress-line-height: 1.6;
		--body-size: 16px; --body-line-height: 1.6;
		--caption-size: 12px; --caption-size-height: 1.5;
	}
}

html, body {
	font-family: 'Albert Sans', sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: $primary-black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	word-wrap: break-word;
}

// body *{
// 	font-family: 'Albert Sans', sans-serif;
// }

a{
	text-decoration: none;
	color: inherit;
}

// Heading //
h1, .heading-1, h2, .heading-2, h3, .heading-3, h4, .heading-4, .sub-heading { font-weight: 500; }
h1, .heading-1 { font-size: var(--heading-1-size)!important; line-height: var(--heading-1-line-height)!important; }
h2, .heading-2 { font-size: var(--heading-2-size)!important; line-height: var(--heading-2-line-height)!important; }
h3, .heading-3 { font-size: var(--heading-3-size)!important; line-height: var(--heading-3-line-height)!important; }
h4, .heading-4 { font-size: var(--heading-4-size)!important; line-height: var(--heading-4-line-height)!important; }
.sub-heading { font-size: var(--sub-heading-size)!important; line-height: var(--sub-heading-line-height)!important; }

// Paragraphs //
.lead-ingress, .body-paragraph { font-weight: 500; }
.lead-ingress { font-size: var(--lead-ingress-size)!important; line-height: var(--lead-ingress-line-height)!important; }
.body-paragraph { font-size: var(--body-size)!important; line-height: var(--body-line-height)!important; }


.main-header{
	width: 100%;
	padding: 48px 0;

	@include media-breakpoint-down(md) {
		padding: calc(16px + 21px) 0 16px 0;
	}

	&__wrapper{
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.active{
			@include media-breakpoint-down(md) {
				&:after{
					content: '';
					width: 100%;
					height: 103px;
					background-color: $secondary-green200;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;
				}
			}
		}

	}

	&__logo{
		@include media-breakpoint-down(md) {
			z-index: 11;
		}

		@media (hover: hover) {
			&:focus-visible{
				@include outline-focus($primary-black, 4px);
			}
		}

		img{
			width: 100%;
			max-width: 200px;

			@include media-breakpoint-down(md) {
				max-width: 100px;
			}
		}
	}

	&__menu{
		height: 100%;
	}

	&__menu-list{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 24px;

		@include media-breakpoint-down(md) {
			gap: 6px;
		}

		@supports (-webkit-touch-callout: none) and (not (translate: none)) {
			& .main-header__menu-item{
				margin-left: 24px;
				@include media-breakpoint-down(md) {
					margin-left: 6px;
				}
			}
		}

	}

	&__menu-search{
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 0 18px;
		min-height: 54px;
		font-size: 18px;
		line-height: 22px;
		cursor: pointer;
		background: transparent;
		border: none;
		border: 2px solid transparent;
		border-radius: 16px;

		@supports (-webkit-touch-callout: none) and (not (translate: none)) {
			& i{
				margin-left: 12px;
				@include media-breakpoint-down(md) {
					margin-left: 0;
				}
			}
		}

		@media (hover: hover) {
			&:hover{
				border-color: $primary-black;
			}

			&:focus-visible{
				@include outline-focus($primary-black, 18px);
				border-color: $primary-black;
			}
		}

		@include media-breakpoint-down(md) {
			padding: 10px 16px;
			min-height: 42px;
			// border: none;
		}

		span{
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		i{
			@include background-image('icons/search-outline.svg', 24, 24);
		}

	}

	&__menu-btn{
		background: transparent;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 10px 32px; // 10px to offset 2px border width, top and bottom
		color: $primary-white;
		background: transparent;
		border-radius: 16px;
		font-size: 18px;
		line-height: 22px;
		min-height: 54px;
		margin: 4px;
		border: 2px solid #301B0A;

		@supports (-webkit-touch-callout: none) and (not (translate: none)) {
			& i{
				margin-left: 12px;
			}
		}

		@include media-breakpoint-down(md) {
			padding: 9px 16px;
			font-size: 16px;
			line-height: 19px;
			min-height: 42px;
		}

		&.login{
			color: $primary-black;
			background: transparent;
			border: 2px solid $primary-black;
			z-index: 11;
			position: relative;


			@include media-breakpoint-down(md) {
				display: none;
			}

			@media (hover: hover) {
				&:hover{
					color: $primary-white;
					background: $primary-black;
					border-color: transparent;
					@include outline-focus($primary-black, 18px);

					i{
						@include background-image('icons/user-light.svg', 24, 24);
					}
				}

				&:focus-visible{
					@include outline-focus($primary-black, 18px);
					border-color: $primary-black;
				}
			}

			i{
				display: block;
				width: 24px;
				height: 24px;
				@include background-image('icons/user-outline.svg', 24,24);
			}

		}

		&.menu{
			color: $primary-white;
			background: $primary-black;
			z-index: 11;
			position: relative;
			width: 154px;
			justify-content: center;

			@include media-breakpoint-down(md) {
				width: auto;
			}

			@media (hover: hover) {
				&:hover{ @include outline-focus($primary-black, 18px) }
				&:focus-visible{ @include outline-focus($primary-black, 18px) }
			}

			&[aria-expanded="false"]{

				@media (hover: hover) {
					&:hover{
						i{
							@include background-image('icons/hamburger-open-light.svg', 30, 30);
							@include media-breakpoint-down(md) {
								@include background-image('icons/hamburger-light.svg', 24, 24);
							}
						}
					}
				}

				i{
					display: block;
					@include background-image('icons/hamburger-light.svg', 30, 30);

					@include media-breakpoint-down(md) {
						height: 24px;
						width: 24px;
					}
				}

				.menu-text{
					display: block;
				}
				.close-text{
					display: none;
				}
			}

			&[aria-expanded="true"]{
				@media (hover: hover) {
					// &:hover{
					// 	i{
					// 		@include background-image('icons/close-hover-light.svg', 30, 30);
					// 		@include media-breakpoint-down(md) {
					// 			@include background-image('icons/close-light.svg', 24, 24);
					// 		}
					// 	}
					// }
				}

				i{
					display: block;
					@include background-image('icons/close-light.svg', 30, 30);

					@include media-breakpoint-down(md) {
						height: 24px;
						width: 24px;
					}
				}

				.menu-text{
					display: none;
				}
				.close-text{
					display: block;
				}
			}
		}

	}

	&__mega-menu{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		height: 100dvh;
		width: 100%;
		background: $secondary-green200;
		z-index: 10;
		padding-top: calc(158px + 123px);
		padding-bottom: 276px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		@include media-breakpoint-down(md) {
			padding-top: 151px;
			padding-bottom: 32px;
		}

		&.active{
			display: block;
		}

		.options{
			margin-bottom: 60px;

			@include media-breakpoint-down(md) {
				padding: 0 34px;
				margin-bottom: 48px;
			}

			li{
				margin-bottom: 16px;
				@include media-breakpoint-down(md) {

				}

				&:last-child{
					margin-bottom: 0;
				}

				> a{
					font-size: 18px;
					line-height: 22px;
					display: inline;
					position: relative;

					@include media-breakpoint-down(md) {
						font-size: 16px;
						line-height: 19px;
					}

					&:after{
						content: '';
						width: 100%;
						height: 2px;
						background-color: $primary-black;
						position: absolute;
						top: calc(100% + 2px);
						left: 0;
						display: none;
					}

					@media (hover: hover) {
						&:hover{
							&:after{
								display: block;
							}
						}
					}

				}

				> div{
					display: inline-block;
				}

				&.featured{
					margin-bottom: 24px;

					@include media-breakpoint-down(md) {
						margin-bottom: 20px;
					}

					&:last-child{
						margin-bottom: 0;
					}

					a{
						font-size: 22px;
						line-height: 130%;

						@include media-breakpoint-down(md) {
							font-size: 20px;
						}

						&:after{
							content: '';
							width: 100%;
							height: 2px;
							background-color: $primary-black;
							position: absolute;
							top: calc(100% + 2px);
							left: 0;
							display: block;
						}

						@media (hover: hover) {
							&:hover{
								span{
									display: block;
								}
							}
						}

						span{
							position: absolute;
							left: calc(100% + 12px);
							top: 0;
							@include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
							display: none;
						}
					}

				}

			}

			.login{
				display: none!important;
				@include media-breakpoint-down(md) {
					display: flex!important;
				}
			}
		}

		.main-header__menu-btn.login{
			@include media-breakpoint-down(md) {
				display: flex;
				margin-left: 0;
			}
		}

	}

}

.main-footer{
	padding: 120px 0;

	@include media-breakpoint-down(md) {
		padding: 60px 0;
	}

	&__logo{
		display: inline-block;
		margin-bottom: 60px;

		@include media-breakpoint-down(md) {
			margin-bottom: 36px;
			margin-left: 35px;
		}

		@media (hover: hover) {
			&:focus-visible{
				@include outline-focus($primary-black, 4px);
			}
		}

		img{
			width: 150px;
		}
	}
	&__group{
		@include media-breakpoint-down(md) {
			margin-bottom: 24px;
			padding: 0 35px;
		}
		li{
			margin-bottom: 8px;
			&:last-child{
				margin: 0;
			}
			&.social{
				p{
					a{
						display: inline-flex;
						align-items: center;
						justify-content: flex-start;
						img{
							margin-right: 24px;
						}
					}
				}
			}
		}
		h2{
			font-size: 22px;
			line-height: 130%;
			color: $primary-black;
			margin-bottom: 16px;
			@include media-breakpoint-down(md) {
				font-size: 20px;
				margin-bottom: 12px;
			}
		}
		p{
			font-size: 18px;
			line-height: 150%;
			color: $primary-black;
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
		}

		&.adress{
			p{
				line-height: 1.7;
				max-width: 240px;
				@include media-breakpoint-down(md) {
					max-width: 200px;
				}
			}
		}
	}

	&__bottom{
		margin-top: 60px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			align-items: flex-start;
			flex-direction: column-reverse;
			margin-top: 12px;
			padding: 0 35px;
		}
	}
	&__logos{
		display: flex;
		align-items: flex-end;
		justify-content: center;
		gap: 24px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 24px;
		}

		@media (hover: hover) {
			a:focus-visible{
				@include outline-focus($primary-black, 4px);
			}
		}

	}
	&__copy{
		p{
			font-size: 18px;
			line-height: 150%;
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
			a{
				text-decoration: underline;
			}
		}
	}
}

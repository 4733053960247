.news{
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    margin-bottom: 44px;
  }

  &__title{
    margin-bottom: 36px;
    display: inline-block;
  }

  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
  }

  &__item{
    background-color: $secondary-beige200;
    padding: 36px;
    margin: 0 18px;
    margin-bottom: 36px;
    width: calc(100% / 3 - 36px);
    text-decoration: none;
    outline: none;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .image{
      margin-bottom: 36px;
      aspect-ratio: 318 / 220;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .text{
      .date{
        margin-bottom: 16px;
      }
      .title{
        margin-bottom: 24px;
        @include media-breakpoint-down(md) {
          margin-top: 16px;
        }
      }
      .intro{
        font-size: 18px;
        line-height: 1.5;
        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }

    .arrow{
      margin-top: 24px;
      @include background-image('icons/arrow-internal-link-outline.svg', 40, 40);
      @include media-breakpoint-down(md) { width: 36px; height: 36px; };
      @include media-breakpoint-down(md) {
        margin-top: 16px;
      }
    }

    @media (hover: hover) {
      &:hover,
      &:focus-visible{
        background-color: $primary-black;

        .text{
          color: $primary-white;
        }

        .arrow{
          border: none;
          outline: none;
          @include background-image('icons/arrow-internal-link-light.svg', 40, 40);
          @include media-breakpoint-down(md) { width: 36px; height: 36px; };
        }

      }
    }

    &[target="_blank"]{

      .arrow{
        @include background-image('icons/arrow-external-link-outline.svg', 40, 40);
        @include media-breakpoint-down(md) { width: 36px; height: 36px; };
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          .arrow{
            @include background-image('icons/arrow-external-link-light.svg', 40, 40);
            @include media-breakpoint-down(md) { width: 36px; height: 36px; };
          }
        }
      }

    }

  }

}

.find-forestry-manager{
  &__wrapper{
    padding-top: 135px;
    padding-bottom: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 44px;
      padding-bottom: 44px;
    }
  }

  &__title{
    margin-bottom: 48px;

    @include media-breakpoint-down(md) {
      margin-bottom: 36px;
    }
    h1{
      margin-bottom: 24px;
    }

  }

  &__search{
    margin-bottom: 120px;

    @include media-breakpoint-down(md) {
      margin-bottom: 44px;
    }

    form{
      display: flex;
      width: 100%;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }

    .search-group{
      width: calc(33.33% - 60px);
      margin-right: 36px;

      @include media-breakpoint-down(md) {
        width: calc(50% - 15px);
        margin-right: 0;
        margin-bottom: 16px;
        &:first-child{
          width: 100%;
        }
        &:last-child{
          margin-left: 30px;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child{
          margin-left: 0;
        }
      }

      label{
        color: $primary-black;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4px;
        display: inline-block;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      .search-input{
        position: relative;
        border-radius: 12px;
        border: 2px solid $primary-black;
        height: 54px;
        background-color: $primary-white;

        &:after{
          content: "";
          width: 20px;
          height: calc(100% + 4px);
          background-color: $primary-black;
          position: absolute;
          right: -2px;
          top: -2px;
          border-radius: 0 12px 12px 0;
          pointer-events: none;
        }

        input{
          width: 100%;
          height: 100%;
          display: inline-block;
          outline: none;
          border: none;
          padding: 0 100px 0 21px;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          color: $primary-black;
          background-color: transparent;
          @include media-breakpoint-down(md) {
            font-size: 18px;
          }
        }
        button{
          position: absolute;
          top: -1px;
          right: -2px;
          padding: 0 24px;
          height: calc(100% + 2px);
          color: $primary-white;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          cursor: pointer;
          background-color: $primary-black;
          border: none;
          border-radius: 0 12px 12px 0;
        }
      }
    }

  }


  &__result{
    .item{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        flex-direction: column!important;
      }

      &:last-child{
        margin-bottom: 0;
      }

      &:nth-child(2n + 2){
        flex-direction: row-reverse;
        justify-content: flex-end;

        .image{
          margin-right: 0;
          margin-left: 80px;
          & + .info{

          }
        }

      }

      .image{
        margin-right: 80px;
        width: calc(50% - 30px);

        @include media-breakpoint-down(md) {
          margin-right: 0!important;
          margin-left: 0!important;
          width: 100%!important;
          // max-width: 540px;
          margin-bottom: 16px;
        }

        & + .info{
          width: calc(50% - 100px);
          @include media-breakpoint-down(md) {
            width: 100%!important;
          }
        }

        figure{
          display: flex;
          aspect-ratio: 482 / 360;
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h3, p{
          margin-bottom: 16px;
          @include media-breakpoint-down(md) {
            margin-bottom: 8px;
            max-width: 540px;
          }
        }

        div{
          margin-bottom: 2px;
          span{
            display: inline-block;
          }
          a{
            word-break: break-all;
            text-decoration-line: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 4px;
            text-decoration-skip-ink: none;
          }
        }

      }
    }
  }

.spiner-wrapper {
    display: block;
    height: 50px;
    position: relative;
    margin-top: 120px;
    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }

  .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
    border-radius: 50%;
  }

  .spinner.smooth::after {
    border-top: 4px solid rgba(48, 27, 10, 1.0);
    border-left: 4px solid rgba(48, 27, 10, 1.0);
    border-right: 4px solid rgba(48, 27, 10, 1.0);
    border-bottom: 4px solid rgba(48, 27, 10, 0.0);
    animation: spinner .6s linear infinite;
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }


}

.hero-home{
  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 44px;
  }

  &__image{
    display: flex;
    aspect-ratio: 1240 / 447;

    @include media-breakpoint-down(sm) {
			aspect-ratio: 342 / 385;
		}

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__box{
    position: relative;
    z-index: 0;
    margin-top: -120px;
    padding-right: 48px;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 24px);
      margin-top: -132px;
      margin-left: auto;
      margin-right: auto;
      padding: 30px 30px 0 30px ;
    }

    &:after{
      content: "";
      width: calc(100% + 60px);
      height: calc(100% + 60px);
      background-color: inherit;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;

      @include media-breakpoint-down(lg){
        width: calc(100% + 48px);
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }

    }

    h1{
      margin-bottom: 36px;
      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
      }
    }
  }

  &__list-links{
    column-count: 2;
    column-gap: 60px;
    margin-top: 70px;

    @include media-breakpoint-down(md) {
      column-count: 1;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 40px;
      margin-top: 30px;
    }

    a{
      display: grid;
      grid-template-rows: 1fr auto;
      break-inside: avoid;
      padding: 18px 68px 18px 16px;
      font-size: 18px;
      line-height: 160%;
      border-top: 2px solid $primary-black;
      border-bottom: 2px solid $primary-black;
      position: relative;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 16px 44px 16px 8px;
        border-bottom: 0;

        &:last-child{
          border-bottom: 2px solid $primary-black;
        }
      }

      &::after{
        content: "";
        @include background-image('icons/arrow-internal-link-outline.svg', 36, 36);
        position: absolute;
        top: 50%;
        margin-top: -18px;
        right: 16px;
        @include media-breakpoint-down(md) {
          width: 24px;
          height: 24px;
          right: 8px;
          margin-top: -12px;
        }
      }

      @media (hover: hover) {
        &:hover,
        &:focus-visible{
          background-color: $primary-black;
          color: $primary-white;
          outline: none;

          &::after{
            @include background-image('icons/arrow-internal-link-light.svg', 36, 36);
            @include media-breakpoint-down(md) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &+ a{
        margin-top: -2px;
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }


      &[target="_blank"]{
        &::after{
          @include background-image('icons/arrow-external-link-outline.svg', 36, 36);
          left: initial;
          right: 16px;
          @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
            left: initial;
            right: 8px;
          }
        }

        @media (hover: hover) {
          &:hover,
          &:focus-visible{

            &::after{
              @include background-image('icons/arrow-external-link-light.svg', 36, 36);
              left: initial;
              right: 16px;
              @include media-breakpoint-down(md) {
                width: 24px;
                height: 24px;
                right:8px;
              }
            }
          }
        }

      }

      &[href*="javascript"]{
        pointer-events: none;
        padding-left: 16px;

        @include media-breakpoint-down(md) {
          padding-left: 8px;
        }

        &:after{
          display: none;
        }
      }

    }

  }
}

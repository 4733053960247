.video{
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 44px;
    }

  &__video{
    aspect-ratio: 758/426;
    padding: 21px;
    border: 2px solid $primary-black;
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 26px;
    }
  }
  &__info{
    @include media-breakpoint-down(sm) {
      padding: 0 26px;
    }
    h2{
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
      }
    }
  }
}
